import React, { useState } from "react";
import "./Pagination.css";

const Pagination = ({
    currentPage,
    totalPages,
    itemsPerPage,
    onPageChange,
    onItemsPerPageChange,
    maxVisiblePages = 3,
    showItemsPerPage = true,
}) => {
    const [startPage, setStartPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        onPageChange(pageNumber);
        updateStartPage(pageNumber);
    };

    const updateStartPage = (pageNumber) => {
        if (pageNumber > startPage + maxVisiblePages - 1) {
            setStartPage(pageNumber - maxVisiblePages + 1);
        } else if (pageNumber < startPage) {
            setStartPage(pageNumber);
        }
    };

    const handleItemsPerPageChange = (event) => {
        onItemsPerPageChange(parseInt(event.target.value, 10));
        setStartPage(1);
    };

    return (
        <div className="pagination-container">
            {/* Previous Button */}
            <button
                className="pagination-btn prev"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                &lt;
            </button>

            {/* Page Numbers */}
            {Array.from(
                { length: Math.min(maxVisiblePages, totalPages) },
                (_, index) => startPage + index
            )
                .filter((page) => page <= totalPages)
                .map((page) => (
                    <button
                        key={page}
                        className={`pagination-btn notactive ${
                            page === currentPage ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </button>
                ))}

            {/* Next Button */}
            <button
                className="pagination-btn next"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                &gt;
            </button>

            {/* Items per page selection */}
            {showItemsPerPage && (
                <select
                    className="items-per-page-select"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                >
                    <option value={4}>4</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                </select>
            )}
        </div>
    );
};

export default Pagination;
