import React, { Component } from "react";
import { toast } from "react-toastify";
import { sortable } from "react-sortable";
import history from "../../../history";
import HeadingComponent from "./HeadingComponent";
import "./materalBannerlist.css";
const MaterialService = require("../../../services/MaterialService");

class CreateBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CreateBanner: [],
        };
    }

    componentDidMount = () => {
        this.fetchData();
    };

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/banner/list").then((res) => {
            if (res.status) {
                this.setState({
                    CreateBanner: res.data,
                    status: res.data.status,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };

    updateStatus = (id, status) => {
        this.setState({ CreateBanner: [] });

        MaterialService.postData("/v2/manager/banner/update-status", {
            id: id,
            status: status,
        }).then((res) => {
            if (res.status) {
                this.fetchData();
            } else {
                // Handle error
            }
        });
    };

    onSortItems = async (items) => {
        const itemsWithOrder = items.map((each, index) => ({
            ...each,
            order: index + 1,
        }));

        this.setState({ CreateBanner: itemsWithOrder });

        const updatedItems = itemsWithOrder.map((item) => ({
            id: item.id,
            order: item.order,
        }));

        await MaterialService.postData(
            "/v2/manager/banner/update-order",
            updatedItems
        );
    };

    render() {
        return (
            <div id="needHelplists" className="need-help-list-main-container">
                <HeadingComponent
                    mainTitle="Materials Banner"
                    title="ADD Materials banner"
                    path="/material-manager/add-material-banners"
                />
                <div className="main-container">
                    <div className="main-need-help-heading">Banners list</div>
                    <div className="col-md-12 row no-padding">
                        <div className="col-md-2 no-padding row-heading"></div>
                        <div className="col-md-2 no-padding row-heading">
                            All Banners
                        </div>
                        <div className="col-md-5 no-padding row-heading">
                            Alt Tag
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Status<i className="fas fa-chevron-down"></i>
                        </div>
                        <div className="col-md-1 no-padding row-heading">
                            Edit
                        </div>
                    </div>
                    <div className="col-md-12 row no-padding">
                        {this.state.CreateBanner.length > 0 ? (
                            <>
                                {this.state.CreateBanner.map((lists, index) => (
                                    <SortableBannerItem
                                        sort
                                        key={lists.id}
                                        sortId={index}
                                        onSortItems={this.onSortItems}
                                        items={this.state.CreateBanner}
                                        lists={lists}
                                        updateStatus={this.updateStatus}
                                    />
                                ))}
                            </>
                        ) : (
                            <div
                                className="alert alert-warning"
                                style={{
                                    width: "max-content",
                                    height: "50px",
                                    textAlign: "center",
                                    margin: "auto",
                                }}
                            >
                                No Data Found
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

class BannerItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { lists, updateStatus } = this.props;
        return (
            <div
                key={lists.id}
                className="col-md-12 row no-padding margin-top need-help-details-contents"
            >
                <div
                    className="col-md-2 no-padding"
                    style={{ minWidth: "140px" }}
                >
                    <i className="fas fa-bars" style={{ cursor: "grab" }}></i>
                </div>
                <div
                    className="col-md-2 no-padding "
                    style={{ minWidth: "140px" }}
                >
                    <img
                        src={`https://${lists.bannerInfo.image.content.storageURL}`}
                        className="col-md-12 no-padding need-list-image"
                        alt={lists.bannerInfo.image.alt}
                    />
                </div>
                <div className="col-md-5">{lists.bannerInfo.image.alt}</div>
                <select
                    className="col-md-2 select-dropdown"
                    name="status"
                    value={lists.status}
                    onChange={(event) => {
                        updateStatus(lists.id, event.target.value);
                    }}
                >
                    <option value="1">Visible</option>
                    <option value="0">Hidden</option>
                    <option value="2">Delete</option>
                </select>
                <div className="col-md-1 banner-edit-button">
                    <i
                        className="fas fa-pencil-alt"
                        aria-hidden="true"
                        onClick={() => {
                            history.push(
                                `/material-manager/edit-banner/${lists.id}`
                            );
                        }}
                    ></i>
                </div>
            </div>
        );
    }
}

class Item extends React.Component {
    render() {
        return (
            <li
                {...this.props}
                style={{ listStyle: "none", padding: "0px", margin: "0px" }}
            >
                <BannerItem {...this.props} />
            </li>
        );
    }
}

const SortableBannerItem = sortable(Item);

export default CreateBanner;
