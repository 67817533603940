export const AUTHENTICATED = 'AUTHENTICATED';
export const SHOWLOGIN = 'SHOWLOGIN';
export const SHOWREGISTER = 'SHOWREGISTER';
export const HIDESIGNUP = 'HIDESIGNUP';
export const HIDELOGIN = 'HIDELOGIN';
export const LOGOUT = 'LOGOUT';
export const SHOWLOCATIONSELECTOR = 'SHOWLOCATIONSELECTOR';
export const SETLOCATION = 'SETLOCATION';
export const HIDELOCATIONSELECTOR = 'HIDELOCATIONSELECTOR';
export const NO_SOCIAL_USER = 'NO_SOCIAL_USER';
export const SHOWPOPUP = 'SHOWPOPUP';
export const CLOSEPOPUP = 'CLOSEPOPUP';
export const KEEPADDING = "KEEPADDING";
export const RECEIVECHATMESSAGE = 'RECEIVECHATMESSAGE';
export const SENDCHATMESSAGE = 'SENDCHATMESSAGE';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SHOW_DONATE_CAUSE = 'SHOW_DONATE_CAUSE';
export const CLOSE_DONATE_CAUSE = 'CLOSE_DONATE_CAUSE';
export const SHOW_MAIN_MENU = 'SHOW_MAIN_MENU';
export const HIDE_MAIN_MENU = 'HIDE_MAIN_MENU';
export const SHOW_CAUSE_MENU = 'SHOW_CAUSE_MENU';
export const HIDE_CAUSE_MENU = 'HIDE_CAUSE_MENU';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

export const SHOW_WISHWATALKS_MENU = 'SHOW_WISHWATALKS_MENU';
export const HIDE_WISHWATALKS_MENU = 'HIDE_WISHWATALKS_MENU';

export const UPDATE_CMS = 'UPDATE_CMS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_LOGGED_IN_USER = 'UPDATE_LOGGED_IN_USER';
